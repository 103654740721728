<template>
  <v-form ref="postPurchase" @submit.prevent="onSubmit">
    <v-row>
      <v-col cols="12" sm="2">
        <h4>Offer's name & message</h4>
      </v-col>
      <v-col cols="12" sm="10" class="elevation-form pa-4">
        <div class="mb-2">Offer's name</div>
        <v-text-field
          solo
          v-model="offer.offerName"
          autocomplete="off"
          type="text"
          clearable
          dense
          flat
          persistent-hint
          placeholder="Offer's name"
          hint="Give your offer a name to manage easily or leave blank and we will automatically generate a name for you"
        ></v-text-field>
        <div class="mb-2">Offer title</div>
        <v-select
          v-model="offer.offerTitle"
          :items="offerTitles"
          item-text="name"
          item-value="id"
          placeholder="Offer’s title"
          required
        ></v-select>
        <div class="mb-2">Offer message</div>
        <v-select
          v-model="offer.offerMessage"
          :items="offerMessages"
          item-text="name"
          item-value="id"
          placeholder="Offer’s message"
          hide-details
          required
        ></v-select>
      </v-col>
    </v-row>
    <v-col cols="12" class="py-6">
      <v-divider class=""></v-divider>
    </v-col>
    <!-- Choose target country -->
    <v-row>
      <v-col cols="12" sm="2">
        <h4>Choose Target Country</h4>
      </v-col>
      <v-col cols="12" sm="10" class="elevation-form pa-4">
        <v-radio-group v-model="typeCountry" hide-details>
          <v-radio value="all" label="All countries"></v-radio>
          <v-radio label="Specific countries" value="specific_countries">
            <template v-slot:label>
              <div class="d-flex flex-column pt-20">
                <span> Specific countries </span>
                <v-btn
                  v-if="typeCountry === 'specific_countries'"
                  depressed
                  width="100%"
                  @click="toggleCountryDialog()"
                  >{{ 'Select countries' }}</v-btn
                >
                <span v-if="typeCountry === 'specific_countries'">{{ countries.length }} countries selected</span>
                <small
                  class="text-error"
                  v-if="typeCountry === 'specific_countries' && countries.length === 0 && isShowErrors"
                >
                  Please select countries.
                </small>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-col cols="12" class="py-6">
      <v-divider></v-divider>
    </v-col>
    <v-row>
      <v-col cols="12" sm="2">
        <h4>Choose Target products</h4>
      </v-col>
      <v-col cols="12" sm="10" class="elevation-form pa-4">
        <v-radio-group v-model="targetType" hide-details="">
          <v-radio value="all" label="All product"> </v-radio>
          <v-radio label="Specific products" value="product">
            <template v-slot:label>
              <div class="d-flex flex-column pt-20">
                <span> Specific products </span>
                <v-btn v-if="targetType === 'product'" depressed width="100%" @click="toggleDialog('product')">{{
                  'Select product'
                }}</v-btn>
                <small
                  class="text-error"
                  v-if="targetType === 'product' && targetProducts.length === 0 && isShowErrors"
                >
                  Please select product(s)/collection(s).
                </small>
                <span v-if="targetType === 'product'">{{ targetProducts.length }} products selected</span>
              </div>
            </template>
          </v-radio>
          <v-radio label=" Specific collections" value="collection">
            <template v-slot:label>
              <div class="d-flex flex-column pt-20">
                <span> Specific collections </span>
                <v-btn v-if="targetType === 'collection'" depressed width="100%" @click="toggleDialog('collection')">{{
                  'Select Collection'
                }}</v-btn>
                <small
                  class="text-error"
                  v-if="targetType === 'collection' && selectedCollections.length === 0 && isShowErrors"
                >
                  Please select product(s)/collection(s).
                </small>
                <span v-if="targetType === 'collection'">{{ selectedCollections.length }} collections selected</span>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-col cols="12" class="py-6">
      <v-divider class=""></v-divider>
    </v-col>
    <v-row>
      <v-col cols="12" sm="2">
        <h4>Offer’s discount</h4>
      </v-col>
      <v-col cols="12" sm="10" class="elevation-form pa-4">
        <div class="group-btn-product lattehub-line-height mb-2" v-for="(offer, index) in offers" :key="index">
          <h4>Step {{ index + 1 }}:</h4>
          <v-row no-gutters>
            <v-col cols="12" sm="8">
              <v-radio-group v-model="offer.recommendType" class="pt-2" hide-details>
                <v-radio label="Specific products" value="product">
                  <template v-slot:label>
                    <div class="d-flex flex-column pt-20">
                      <span> Specific products </span>
                      <v-btn
                        v-if="offer.recommendType === 'product'"
                        depressed
                        width="100%"
                        @click="toggleDialog(index)"
                        >{{
                          offer.productTargetIds.length
                            ? `${offer.productTargetIds.length} products selected`
                            : 'Select Products'
                        }}</v-btn
                      >
                      <small
                        class="text-error"
                        v-if="offer.recommendType === 'product' && offer.productTargetIds.length === 0 && isShowErrors"
                      >
                        Please select product(s)/collection(s).
                      </small>
                    </div>
                  </template>
                </v-radio>
                <v-radio label="Specific collections" value="collection">
                  <template v-slot:label>
                    <div class="d-flex flex-column pt-20">
                      <span> Specific collections </span>
                      <v-btn
                        v-if="offer.recommendType === 'collection'"
                        depressed
                        width="100%"
                        @click="toggleDialog(index)"
                        >{{
                          offer.collectionRecommendIds.length
                            ? `${offer.collectionRecommendIds.length} collections selected`
                            : 'Select Collection'
                        }}</v-btn
                      >
                      <small
                        class="text-error"
                        v-if="
                          offer.recommendType === 'collection' &&
                            offer.collectionRecommendIds.length === 0 &&
                            isShowErrors
                        "
                      >
                        Please select product(s)/collection(s).
                      </small>
                    </div>
                  </template>
                </v-radio>
                <v-radio value="sameCollection" label="Same collection with target products"> </v-radio>
                <v-radio value="sameNiche" label="Same niche"> </v-radio>
                <v-radio value="currentProduct" label="Add Current Products" v-if="index == 0"> </v-radio>
                <v-radio value="sameTitle" label="Same title"> </v-radio>
                <div class="d-flex">
                  <v-radio value="relevantCollection" label="Most relevant products using automated rules"></v-radio>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon color="grey lighten-1">
                          mdi-information
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      Recommend with this priority:<br />
                      - Bought also Bought<br />
                      - Best-seller products in the same collection<br />
                      - Title Similarity<br />
                      - Same collection<br />
                      - Relative tags products<br />
                      - Random<br />
                    </span>
                  </v-tooltip>
                </div>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="4">
              <div>
                <label class="font-weight-medium">Limited product</label>
                <v-text-field
                  solo
                  v-model="offer.maxQuantity"
                  autocomplete="off"
                  :disabled="['currentProduct', 'product'].includes(offer.recommendType)"
                  clearable
                  dense
                  flat
                  :rules="isNumberField"
                ></v-text-field>
              </div>
              <div>
                <label class="font-weight-medium">{{ 'Select custom value' }}</label>
                <v-select
                  v-model="offer.personalizedName"
                  :items="customeValues"
                  item-text="value"
                  item-value="key"
                  required
                ></v-select>
                <v-text-field
                  solo
                  v-model="offer.defaultValue"
                  autocomplete="off"
                  clearable
                  dense
                  flat
                  placeholder="eg: 01/01/2021"
                  :hide-details="true"
                  :rules="requiredField"
                  v-if="offer.personalizedName == 'default'"
                ></v-text-field>
              </div>
              <div>
                <label class="font-weight-medium">Discount percent</label>
                <v-text-field
                  solo
                  v-model="offer.valueDiscount"
                  autocomplete="off"
                  type="number"
                  clearable
                  dense
                  flat
                  :rules="isNumberField"
                ></v-text-field>
              </div>
              <div class="d-flex justify-end">
                <v-btn v-if="index > 0" icon color="error" @click="removeProduct(index)"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </div>
            </v-col>
            <v-col cols="12" class="py-4">
              <v-divider class=""></v-divider>
            </v-col>
          </v-row>
        </div>
        <v-btn depressed color="primary" @click="addMoreOffer">
          Add more
          <v-icon right dark>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-col cols="12" class="my-4">
      <v-divider class=""></v-divider>
    </v-col>
    <v-row>
      <v-col cols="12" sm="2">
        <h4 class="d-flex align-center">Options</h4>
      </v-col>
      <v-col cols="12" sm="10" class="elevation-form pa-4">
        <h4 class="mb-2">Charges for shipping:</h4>
        <label class="d-block mb-2 font-weight-medium">Rate:</label>
        <v-checkbox v-model="isFreeShipping" label="Free shipping" class="mb-2" hide-details></v-checkbox>
        <div v-if="!isFreeShipping">
          <label class="d-block mb-1 font-weight-medium">Rate amount:</label>
          <div style="width: 200px;">
            <v-text-field
              type="number"
              v-model="shippingRate"
              :prefix="currency.currencyCode()"
              :rules="isNumberField"
              hide-details
            ></v-text-field>
          </div>
        </div>
        <v-divider class="my-4"></v-divider>
        <div class="d-flex align-center mb-4">
          <label class="mr-2 font-weight-medium">Time:</label>
          <v-text-field
            type="number"
            v-model="countdownTime"
            :rules="isNumberField"
            hide-details
            style="max-width: 150px"
          >
          </v-text-field>
          <div class="ml-2">minutes</div>
        </div>
        <v-switch v-model="viewInOrder" label="View my order button" hide-details></v-switch>
        <!-- < -->
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <!-- :items="items" -->
        <v-dialog :scrollable="false" v-model="dialog" width="900" content-class="l-dialog">
          <SelectionItems
            :model="modelName"
            :type="popupTarget"
            @returnData="getSelectedData"
            :dialog.sync="dialog"
            :dataSelected="model"
            :isSuperShow="true"
          />
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <v-dialog :scrollable="false" v-model="dialogCountry" width="900" content-class="l-dialog">
          <CountrySelector
            @returnData="getSelectedCountries"
            :dialog.sync="dialogCountry"
            :dataSelected="countries"
            :isSuperShow="true"
          />
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex">
        <v-spacer></v-spacer>
        <v-btn depressed class="mr-3" @click="onCancel">Discard</v-btn>
        <!-- @click="onSubmit" -->
        <v-btn :loading="isLoading" color="primary" type="submit">{{ type == 'create' ? 'Create' : 'Update' }}</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import SelectionItems from '../../component/SelectionItems';
import { offerApi } from '@/apis/offers';
import { convertIdsToArrays } from '@/helpers/convertData';
import currency from '@/helpers/currency';

export default {
  props: {
    type: {
      type: String,
      default: 'create',
    },
    defaultData: {
      type: Object,
      default: function() {
        return {};
      },
    },
    directToBootUpSell: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SelectionItems,
  },
  data() {
    this.defaultData?.discountData?.map(offer => {
      if (!offer.recommendType) offer.recommendType = 'product';
      if (!offer.maxQuantity) offer.maxQuantity = 1;
      return offer;
    });
    return {
      currency,
      targetType: this.defaultData.targetType || 'all', // this.$parent.formData.targetType
      countries: this.defaultData.countries || [],
      typeCountry: this.defaultData.countries?.length > 0 ? 'specific_countries' : 'all',
      viewInOrder: this.defaultData.viewInOrder !== undefined ? this.defaultData?.viewInOrder : true,
      countdownTime: this.defaultData.countdownTime || 5,
      model: [],
      modelName: '',
      popupType: 'products',
      offer: {
        offerName: this.defaultData.offerName || '',
        offerTitle: this.defaultData.offerTitle || '',
        offerMessage: this.defaultData.offerMessage || '',
      },
      dialog: false,
      customeValues: [
        {
          key: 'personalized',
          value: 'Custom name',
        },
        {
          key: 'first',
          value: 'First name',
        },
        {
          key: 'last',
          value: 'Last name',
        },
        {
          key: 'default',
          value: 'Default Value',
        },
      ],
      offers: this.defaultData.discountData || [
        {
          targetProducts: [],
          personalizedName: 'personalized',
          valueDiscount: 0,
          discountType: 'percentageEach',
          productTargetIds: [],
          defaultValue: '',
          recommendType: 'product',
          targetCollections: [],
          productRecommendIds: [],
          collectionRecommendIds: [],
          maxQuantity: 1,
        },
      ],
      selectedCollections:
        this.defaultData.targetType == 'collection' && this.defaultData.targetIds ? this.defaultData.targetIds : [],
      targetProducts:
        this.defaultData.targetType == 'product' && this.defaultData.targetIds ? this.defaultData.targetIds : [],
      dialogCountry: false,
      currentOfferIndex: 0,
      isLoading: false,
      offerMessages: [
        {
          name:
            'Pick one of these offers and pocket instant savings of up to {{discount}} off. This offer is time sensitive and only available for next few minutes...',
          id:
            'Pick one of these offers and pocket instant savings of up to {{discount}} off. This offer is time sensitive and only available for next few minutes...',
        },
        {
          name: 'This offer is time sensitive and only available for next few minutes...',
          id: 'This offer is time sensitive and only available for next few minutes...',
        },
        {
          name: 'Last chance for only you to get this lower price products with just 1 click.',
          id: 'Last chance for only you to get this lower price products with just 1 click.',
        },
        {
          name: 'As a special thank you, I will give you an amazing offer you will like more.',
          id: 'As a special thank you, I will give you an amazing offer you will like more.',
        },
      ],
      offerTitles: [
        {
          name: 'Wait {{last_name}}! Weve got a special offer for you that complements your purchase.',
          id: `Wait {{last_name}}! We've got a special offer for you that complements your purchase.`,
        },
        {
          name: 'One-Time Offer!',
          id: 'One-Time Offer!',
        },
        {
          name: 'Special last offer for you only!',
          id: 'Special last offer for you only!',
        },
        {
          name: 'The last deal today for lucky customers like you',
          id: 'The last deal today for lucky customers like you',
        },
        {
          name: `Before you go, I'm making an amazing offer for you`,
          id: `Before you go, I'm making an amazing offer for you`,
        },
        {
          name: `Wait {{last_name}}! You won't see this offer again`,
          id: `Wait {{last_name}}! You won't see this offer again`,
        },
      ],
      isFreeShipping: this.defaultData?.isFreeShipping !== undefined ? this.defaultData?.isFreeShipping : true,
      shippingRate: this.defaultData?.shippingRate || null,
      // items: [],
      isNumberField: [
        v => !!v || 'This field is required',
        v => (v && v > 0) || 'This field must be greater than zero!',
      ],
      requiredField: [v => !!v || 'This field is required'],
      isShowErrors: false,
    };
  },
  methods: {
    increment() {
      this.countdownTime = this.countdownTime ? parseInt(this.countdownTime, 10) + 1 : 1;
    },
    async toggleDialog(target) {
      this.isShowErrors = false;
      if (target === 'collection') {
        this.popupType = 'collection';
        this.modelName = 'selectedCollections';
        this.model = this.selectedCollections;
        // this.items = [];
      } else if (target === 'product') {
        this.modelName = 'targetProducts';
        this.popupType = 'product';
        this.model = this.targetProducts;
        // this.items = [];
      } else {
        // Đoạn này cần lấy về danh sách product đã chọn
        // this.items = this.targetProducts.length > 0 ? new Array().concat(this.targetProducts) : [];
        if (this.offers[target]?.recommendType == 'product') {
          this.popupType = 'product';
          this.modelName = `${target}`;
          this.currentOfferIndex = target;
          const productTargetIds = this.offers[target]?.productTargetIds;
          if (!this.offers[target]?.productTarget?.length !== productTargetIds?.length) {
            const products = await this.getSelectedProducts(productTargetIds);
            this.offers[target].productTarget = products;
          }
          this.model = this.offers[target]?.productTarget;
        } else {
          this.popupType = 'collection';
          this.modelName = `${target}`;
          this.currentOfferIndex = target;
          const collectionRecommendIds = this.offers[target]?.collectionRecommendIds;
          if (!this.offers[target]?.targetCollections?.length !== collectionRecommendIds?.length) {
            const collections = await this.getSelectedCollections(collectionRecommendIds);
            this.offers[target].targetCollections = collections;
          }
          this.model = this.offers[target]?.targetCollections;
        }
      }

      this.dialog = true;
    },
    addMoreOffer() {
      this.offers.push({
        productTargetIds: [],
        personalizedName: 'personalized',
        valueDiscount: 0,
        discountType: 'percentageEach',
        productTarget: [],
        recommendType: 'product',
        productRecommendIds: [],
        collectionRecommendIds: [],
        maxQuantity: 1,
      });
    },
    removeProduct(index) {
      this.offers.splice(index, 1);
    },
    getSelectedData(data, target) {
      if (target == 'selectedCollections') {
        this.selectedCollections = data;
      } else if (target == 'targetProducts') {
        this.targetProducts = data;
      } else {
        if (this.offers[parseInt(target)]?.recommendType == 'product') {
          this.currentOfferIndex = target;
          this.offers[parseInt(target)].productTarget = data;
          let ids = data?.map(item => {
            return item?._id || item?.id;
          });
          this.offers[parseInt(target)].productTargetIds = ids;
          this.offers[parseInt(target)].productRecommendIds = ids;
        } else {
          this.currentOfferIndex = target;
          this.offers[parseInt(target)].targetCollections = data;
          let ids = data?.map(item => {
            return item?._id || item?.id;
          });
          this.offers[parseInt(target)].collectionRecommendIds = ids;
        }
      }
      return data;
    },
    toggleCountryDialog() {
      this.isShowErrors = false;
      this.dialogCountry = true;
    },
    getSelectedCountries(data) {
      this.countries = data;
      return data;
    },
    onSubmit(e) {
      e.preventDefault();
      let isValid = this.validate();
      this.isShowErrors = !isValid;
      console.log(this.isShowErrors, isValid);
      if (isValid && !this.isShowErrors) this.sendResquest();
    },
    validate() {
      const formIsValid = this.$refs.postPurchase.validate();
      let discountValid = true;
      this.offers.map(offer => {
        if (offer.recommendType === 'product' && offer?.productTargetIds?.length <= 0) {
          discountValid = false;
        }

        if (offer.recommendType === 'collection' && offer?.collectionRecommendIds?.length <= 0) {
          discountValid = false;
        }
      });
      let targetIsValid =
        this.targetType === 'all' ||
        (this.targetType === 'product' && this.targetProducts.length > 0) ||
        (this.targetType === 'collection' && this.selectedCollections.length > 0);
      let targetCountryIsValid =
        this.typeCountry === 'all' || (this.typeCountry === 'specific_countries' && this.countries.length > 0);
      return formIsValid && targetIsValid && targetCountryIsValid && discountValid;
    },
    async sendResquest() {
      this.isShowErrors = false;
      let productTargetIds = [],
        collectionTargetIds = [];
      this.targetProducts.map(product => {
        productTargetIds.push(product?._id);
      });
      this.selectedCollections.map(colection => {
        collectionTargetIds.push(colection?._id);
      });
      this.offers.map(offer => {
        if (offer.recommendType == 'product') {
          offer.collectionRecommendIds = [];
        } else if (offer.recommendType == 'collection') {
          offer.productRecommendIds = [];
          offer.productTargetIds = [];
        } else {
          offer.productRecommendIds = [];
          offer.productTargetIds = [];
          offer.collectionRecommendIds = [];
        }
      });

      let reqData = {
        activated: true,
        viewInOrder: this.viewInOrder,
        offerType: 'postPurchase',
        ...this.offer,
        productTargetIds: productTargetIds,
        countries: this.typeCountry === 'all' ? [] : this.countries,
        collectionTargetIds: collectionTargetIds,
        countdownTime: this.countdownTime,
        enableDiscount: true,
        discountData: this.offers,
        recommendType: collectionTargetIds?.length > 0 ? 'collection' : 'product',
        targetType: this.targetType,
        isFreeShipping: this.isFreeShipping.toString(),
        shippingRate: this.shippingRate,
      };
      try {
        this.isLoading = true;
        let response = null;
        if (this.type == 'create') {
          response = await offerApi.create({ offer: reqData });
        } else {
          response = await offerApi.update(this.$route.params.id, reqData);
        }

        this.isLoading = false;
        if (this.directToBootUpSell) {
          this.$router.push({
            name: 'website.upsell.post_purchase',
          });
        } else
          this.$router.push({
            name: 'website.apps.upsell.list',
            query: { tab: 'prePurchase,inCart,postPurchase', limit: 50 },
          });
      } catch (error) {
        this.isLoading = false;
      }
    },
    onCancel() {
      // this.$v.$reset();
      // this.$forceUpdate();
      //website.collections
    },
    countSelectedPorduct: function(product) {
      return product.targetProducts && product.targetProducts.length
        ? `${product.targetProducts.length} products selected`
        : product.targetProductIds.length > 0
        ? `${product.targetProductIds.length} products selected`
        : 'Select product';
    },
    async getSelectedProducts(productIds) {
      this.isLoading = true;
      const products = await convertIdsToArrays('product', productIds);
      this.isLoading = false;
      return products;
    },
    async getSelectedCollections(collectionIds) {
      this.isLoading = true;
      const collections = await convertIdsToArrays('collection', collectionIds);
      this.isLoading = false;
      return collections;
    },
    showErrors(product) {
      return product.targetProductIds.length === 0 && this.isShowErrors;
    },
  },
  computed: {
    popupTarget() {
      return `${this.popupType}s`;
    },
  },
};
</script>

<style lang="scss" scoped>
.lattehub-line-height {
  line-height: 2.5;
}
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
.v-input {
  font-size: 14px;
}
.form-component label {
  font-size: 12px;
}
</style>
